import ApiRequest, { requestByTempToken } from "./ApiRequest";
import { getAuthorization } from "../utils/CookieUtil";

export const Api = new ApiRequest(process.env.REACT_APP_API_URL);
export const ItlinkApi = new ApiRequest(process.env.REACT_APP_ITLINK_API_URL);

// 공통. statusCd group 호출
export function getStatusCd(groupCode, parentCode, parentGroupCode) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/common/codes?groupCode=${groupCode}&parentCode=${parentCode}&parentGroupCode=${parentGroupCode}`,
    accessToken,
  );
}

// 공통. S3 - private 업로드 - cognitoId/token
export function getS3Auth() {
  const accessToken = getAuthorization();
  return Api.get("/api/common/cognito/tuneit-admin", accessToken);
}

// 공통. S3 - private - 사업자등록증 미리보기
export function getPrivateRedirect(fileId) {
  const accessToken = getAuthorization();
  return Api.get(`/api/common/private-file/${fileId}`, accessToken);
}

// 공통. 메뉴 조회(권한적용) => 메뉴 보여주는 (토큰로그인 후)
export function getRoleMenuAfterTokenLogin(token) {
  return Api.get("/api/menu-mgmt/role-menu", token);
}

export function getRoleMenu() {
  const accessToken = getAuthorization();
  return Api.get("/api/menu-mgmt/role-menu", accessToken);
}

// 공통. 국가 조회
export function getCountry(countryName) {
  const accessToken = getAuthorization();
  return Api.get(`/api/common/country`, accessToken);
}

export function getTimezone(countryCode) {
  const accessToken = getAuthorization();
  return Api.get(`/api/common/timezone/${countryCode}`, accessToken);
}

// --------------------- 로그인 관련 ------------------------- //
// A1. 마스터 로그인
export function signIn(body) {
  return Api.post("/api/sign-in", null, body);
}

// A1-1. 마스터 토큰 로그인
export function loginByToken() {
  const accessToken = getAuthorization();
  return Api.get("/api/sign-in/token", accessToken);
}

// A1-2. 마스터 로그아웃
export function logout() {
  const accessToken = getAuthorization();
  return Api.post("/api/sign-out", accessToken);
}

// A2. 비밀번호 재설정
export function resetPassword(tempToken, adminNo, oldPasswd, passwd) {
  let body = {};
  if (!passwd) {
    // 5회, 6개월 비밀번호 재설정
    body = { passwd: oldPasswd };
  } else {
    // 최초 로그인시 비밀번호 재설정
    body = { oldPasswd, passwd };
  }
  let url = `/api/sign-in/member/${adminNo}/pwd`;
  return requestByTempToken(
    "PATCH",
    process.env.REACT_APP_API_URL + url,
    tempToken,
    body,
  );
}

// A3. Google OTP SecretKey 최초 발급
export function getOTPSecretKey(adminNo, tempToken) {
  let url = `/api/sign-in/member/${adminNo}/otps`;
  return requestByTempToken(
    "POST",
    process.env.REACT_APP_API_URL + url,
    tempToken,
  );
}

// A3-1. Google OTP 인증번호 확인
export function checkOTPSecretKey(adminNo, otpCode, tempToken) {
  let url = `/api/sign-in/member/${adminNo}/otps/${otpCode}`;
  return requestByTempToken(
    "GET",
    process.env.REACT_APP_API_URL + url,
    tempToken,
  );
}

// A4. 이메일 인증코드 발송
export function sendEmailAuthCode(adminNo, tempToken, firstYn) {
  if (firstYn) {
    let url = `/api/sign-in/member/${adminNo}/email-auth-code?firstYn=Y`;
    return requestByTempToken(
      "POST",
      process.env.REACT_APP_API_URL + url,
      tempToken,
    );
  }
  let url = `/api/sign-in/member/${adminNo}/email-auth-code`;
  return requestByTempToken(
    "POST",
    process.env.REACT_APP_API_URL + url,
    tempToken,
  );
}

// A4-1. 이메일 인증코드 확인
export function checkEmailAuthCode({ adminNo, authCode, tempToken }, firstYn) {
  if (firstYn) {
    let url = `/api/sign-in/member/${adminNo}/email-auth-code/${authCode}?firstYn=Y`;
    return requestByTempToken(
      "GET",
      process.env.REACT_APP_API_URL + url,
      tempToken,
    );
  }
  let url = `/api/sign-in/member/${adminNo}/email-auth-code/${authCode}`;
  return requestByTempToken(
    "GET",
    process.env.REACT_APP_API_URL + url,
    tempToken,
  );
}

// A5. 홈 - 비밀번호 변경
export function patchHomeResetPw(adminNo, body) {
  const accessToken = getAuthorization();
  return Api.patch(
    `/api/sign-in/member/${adminNo}/reset-pwd`,
    accessToken,
    body,
  );
}

// A5-1. 홈 - 서비스 현황 - 회원 현황
export function getMemberStatus() {
  const accessToken = getAuthorization();
  return Api.get(`/api/service-situation/member`, accessToken);
}

// A5-2. 홈 - 서비스 현황 - 디지털 차키 사용 현황
export function getSmartKeyStatus() {
  const accessToken = getAuthorization();
  return Api.get(`/api/service-situation/smart-key`, accessToken);
}

// A5-3. 홈 - 서비스 현황 - 자동차 현황
export function getVehicleStatus() {
  const accessToken = getAuthorization();
  return Api.get(`/api/service-situation/vehicle`, accessToken);
}

// D1-1. 목록조회 - Device 카테고리 & D2-1 등록 - Device 카테고리

export function getDeviceCategory(str) {
  const accessToken = getAuthorization();
  return Api.get(`/api/common/codes?groupCode=${str}`, accessToken);
}

// D2-1 등록 - Device 카테고리 (D1-1. 목록조회 - Device 카테고리와 동일)

// D2-2 등록 - Feature (Device 카테고리(D2-1)를 선택해야함)
export function getFeatureCategory(code) {
  const accessToken = getAuthorization();
  const query = {
    groupCode: "FEATURE_ID",
    parentGroupCode: "DEVICE_ID",
    parentCode: code,
  };
  return Api.get("/api/common/codes", accessToken, query);
}

// D3-1. 디바이스관리 - 펌웨어 버전관리 - 목록조회
export function getFirmwareVersionList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = { page, size, ...queryList };
  return Api.get(`/api/firmware-mgmt/versions`, accessToken, query);
}

// D3-2. 디바이스관리 - 펌웨어 버전관리 - 상세조회
export function getFirmwareVersionDetail(firmwareNo) {
  const accessToken = getAuthorization();
  return Api.get(`/api/firmware-mgmt/versions/${firmwareNo}`, accessToken);
}

// D3-3. 디바이스관리 - 펌웨어 버전관리 - 수정
export function patchFirmwareVersion(firmwareNo, body) {
  const accessToken = getAuthorization();
  return Api.patch(
    `/api/firmware-mgmt/versions/${firmwareNo}`,
    accessToken,
    body,
  );
}

// D3-4. 디바이스관리 - 펌웨어 버전관리 - 삭제
export function deleteFirmwareVersion(firmwareNo) {
  const accessToken = getAuthorization();
  return Api.delete(`/api/firmware-mgmt/versions/${firmwareNo}`, accessToken);
}

// D3-4. 디바이스관리 - 펌웨어 버전관리 - 등록
export function postFirmwareVersion(body) {
  const accessToken = getAuthorization();
  return Api.post(`/api/firmware-mgmt/versions/firmware`, accessToken, body);
}

// D4-0. 배포 상세조회 - 배포취소 : ['진행중'(100) 상태만 취소 가능] (펌웨어, fbp 모두 사용)
export function deleteDistribution(id) {
  const accessToken = getAuthorization();
  return Api.put(`/api/distribution-mgmt/${id}/cancel`, accessToken);
}

// D4-0-1. 배포 상세조회 - 배포대상 취소(삭제) : QUEUED 상태만 취소 가능 (펌웨어, fbp 모두 사용)
export function deleteDistributionThings(jobId, body) {
  const accessToken = getAuthorization();
  return Api.delete(
    `/api/distribution-mgmt/${jobId}/things`,
    accessToken,
    body,
  );
}

// D4-0-2. 디바이스관리 - 펌웨어배포관리 - 배포대상 조회 (펌웨어, fbp 모두 사용)
export function getDistributionThingsList(jobId, thingName, nextToken) {
  const accessToken = getAuthorization();
  const query = { nextToken, thingName };
  return Api.get(`/api/distribution-mgmt/${jobId}/things`, accessToken, query);
}

// D4-1. 디바이스관리 - 펌웨어배포관리 - Firmware 목록조회
// DeviceId : (D1-1. 목록조회 - Device 카테고리와 동일)
// featureId : D2-2 등록 - Feature (Device 카테고리(D2-1)를 선택해야함)
export function getFirmwaresList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = { page, size, ...queryList };
  return Api.get(`/api/distribution-mgmt/firmwares`, accessToken, query);
}

// D4-2. 디바이스관리 - 펌웨어배포관리 - Firmware 상세조회
export function getFirmwareDetail(id) {
  const accessToken = getAuthorization();
  return Api.get(`/api/distribution-mgmt/firmwares/${id}`, accessToken);
}

// D4-4. 디바이스관리 - 펌웨어배포관리 - Firmware 배포 등록
// D4-4-1. DEVICE , Feature, 펌웨어 선택
export function getDeviceCate(str) {
  const accessToken = getAuthorization();
  return Api.get(`/api/common/codes?groupCode=DEVICE_ID&${str}`, accessToken);
}

export function getFeatureCate(code) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/common/codes?groupCode=FEATURE_ID&parentCode=${code}`,
    accessToken,
  );
}

export function getFirmwareList(deviceId, featureId) {
  const accessToken = getAuthorization();
  const queryList = { deviceId, featureId };
  return Api.get(`/api/firmware-mgmt/versions-list`, accessToken, queryList);
}

// D4-4-2 등록버튼
export function postFirmwareIotJob(body) {
  const accessToken = getAuthorization();
  return Api.post(`/api/distribution-mgmt/firmwares/iotjob`, accessToken, body);
}

// D4-5. 디바이스관리 - 펌웨어배포관리 - Firmware 배포 등록 - 차량조회
export function getFirmwareForVehicles(queryList) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/distribution-mgmt/firmwares/vehicles`,
    accessToken,
    queryList,
  );
}

// FBP
// D4-7. 디바이스관리 - 펌웨어배포관리 - FBP 목록조회
export function getFbpList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = { page, size, ...queryList };
  return Api.get(`/api/distribution-mgmt/fbps`, accessToken, query);
}

// D4-8. 디바이스관리 - 펌웨어배포관리 - FBP 상세조회
export function getFbpDetail(id) {
  const accessToken = getAuthorization();
  return Api.get(`/api/distribution-mgmt/fbps/${id}`, accessToken);
}

// D4-9. 디바이스관리 - 펌웨어배포관리 - FBP 배포등록 - FBP 버전 조회
export function getFbpVersion() {
  const accessToken = getAuthorization();
  return Api.get(`/api/distribution-mgmt/fbps/db-versions`, accessToken);
}

// D4-10. 디바이스관리 - 펌웨어배포관리 - FBP 배포등록 - FBP검색
export function getFbpModelsCategory(query) {
  // API구분(M:제조사, T:모델, R:연식, F:유종, D:배기량, E:연비, C:승차정원, L:전장, S:세부모델)
  const accessToken = getAuthorization();
  return Api.get(`/api/distribution-mgmt/fbps/models`, accessToken, query);
}

// D4-11. 디바이스관리 - 펌웨어배포관리 - FBP 배포등록 - FBP 버전 검색 ( 카테고리 선택완료 후)
export function getFbpVersions(query) {
  const accessToken = getAuthorization();
  return Api.get(`/api/distribution-mgmt/fbps/versions`, accessToken, query);
}

// D4-12. 디바이스관리 - 펌웨어배포관리 - FBP 배포 등록 - 차량조회
export function getFbpForVehicles(queryList) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/distribution-mgmt/fbps/vehicles`,
    accessToken,
    queryList,
  );
}

// D4-13. 디바이스관리 - 펌웨어배포관리 - FBP 배포 등록 - 등록버튼
export function postFbpIotJob(body) {
  const accessToken = getAuthorization();
  return Api.post(`/api/distribution-mgmt/fbps/iotjob`, accessToken, body);
}

// E1-1. 개통관리 - 목록조회
export function getActivateList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = { page, size, ...queryList };
  return Api.get(`/api/device-activate-mgmt/devices`, accessToken, query);
}

// E1-2. 개통관리 - 상세조회
export function getActivateDetail(serial) {
  const accessToken = getAuthorization();
  return Api.get(`/api/device-activate-mgmt/devices/${serial}`, accessToken);
}

// E1-3. 개통관리 - 수정
export function putActivate(serial, body) {
  const accessToken = getAuthorization();
  return Api.put(
    `/api/device-activate-mgmt/devices/${serial}`,
    accessToken,
    body,
  );
}

// E1-4. 개통관리 - 해지(초기화)
export function patchActivateReset(serial) {
  const accessToken = getAuthorization();
  return Api.patch(
    `/api/device-activate-mgmt/devices/${serial}/reset`,
    accessToken,
  );
}

// E1-5. 개통관리 - 개통 정보 일괄 등록
export function putActivateInfoRegister(fileName) {
  const accessToken = getAuthorization();
  return Api.put(
    `/api/device-activate-mgmt/devices/list?filename=${fileName}`,
    accessToken,
  );
}

// E1-6. 개통관리 - 전체 목록 다운로드
export function getDownloadList() {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/device-activate-mgmt/devices/download/list`,
    accessToken,
  );
}

// H1. 자동차관리 - 자동차정보 - 목록조회
export function getVehiclesList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = { page, size, ...queryList };
  return Api.get(`/api/vehicle-mgmt/vehicles`, accessToken, query);
}

// H1-1. 자동차관리 - 자동차정보 - 상세조회
export function getVehiclesDetail(carNum) {
  const accessToken = getAuthorization();
  return Api.get(`/api/vehicle-mgmt/vehicles/${carNum}`, accessToken);
}

// H1-2-1. 자동차관리 - 자동차정보 - 자동차 등록 - 기업검색
export function getCommonCompanysList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = `page=${page}&size=${size}&searchType=${queryList.searchType}&searchText=${queryList.searchText}`;

  return Api.get(
    `/api/common/companys?${query}&comTypeCd=300&comTypeCd=400`,
    accessToken,
  );
}

// H1-2-2. 자동차관리 - 자동차정보 - 자동차 등록 - 기업검색
export function getCommonMembersList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = { page, size, ...queryList };
  return Api.get(`/api/common/members`, accessToken, query);
}

// H1-2-3. 자동차관리 - 자동차정보 - 자동차 등록 - 등록하기 (itlink API 사용)
export function postVehiclesRegister(reqVehicleCreation) {
  const accessToken = getAuthorization();
  return ItlinkApi.post(
    `/api/admin-service/v1/vehicle`,
    accessToken,
    reqVehicleCreation,
  );
}

// H1-4. 자동차관리 - 자동차정보 - 상세조회 - 등록가능 디바이스 조회
export function getVehiclesDeviceRegable(carNum, serial) {
  const accessToken = getAuthorization();
  const query = {
    serial,
  };
  return Api.get(
    `/api/vehicle-mgmt/vehicles/${carNum}/device-regable`,
    accessToken,
    query,
  );
}

// H1-5. 자동차관리 - 자동차정보 - 상세조회 - 디바이스 등록 (itlink API 사용)
export function postVehiclesDevice(carNum, serial) {
  const accessToken = getAuthorization();
  const body = { carNum, serial };
  return ItlinkApi.post(
    `/api/admin-service/v1/vehicle/${carNum}/node`,
    accessToken,
    body,
  );
}

// H1-5-1. 자동차관리 - 자동차정보 - 상세조회 - 디바이스 해제 (itlink API 사용)
export function deleteVehiclesDevice(carNum, serial) {
  const accessToken = getAuthorization();
  return ItlinkApi.delete(
    `/api/admin-service/v1/vehicle/${carNum}/node/${serial}`,
    accessToken,
  );
}

// H1-6. 자동차관리 - 자동차정보 - 상세조회 - 디바이스 정보
export function getVehiclesDevices(carNum) {
  const accessToken = getAuthorization();
  return Api.get(`/api/vehicle-mgmt/vehicles/${carNum}/devices`, accessToken);
}

// H1-7. 자동차관리 - 자동차정보 - 상세조회 - 디바이스 등록이력
export function getVehiclesDevicesHistory(carNum) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/vehicle-mgmt/vehicles/${carNum}/devices-hist`,
    accessToken,
  );
}

// H1-8. 자동차관리 - 자동차정보 - 상세조회 - 게스트 사용자 정보 조회
export function getGuestsList(carNum) {
  const accessToken = getAuthorization();
  return Api.get(`/api/vehicle-mgmt/vehicles/${carNum}/guests`, accessToken);
}

// H1-9. 자동차관리 - 자동차정보 - 상세조회 - 월간 리포트
export function getVehiclesDeviceReport(carNum) {
  const accessToken = getAuthorization();
  return Api.get(`/api/vehicle-mgmt/vehicles/${carNum}/report`, accessToken);
}

// H1-10. 자동차관리 - 자동차정보 - 상세조회 - 자동차 상태
export function getVehiclesDeviceStatus(carNum) {
  const accessToken = getAuthorization();
  return Api.get(`/api/vehicle-mgmt/vehicles/${carNum}/status`, accessToken);
}

// H1-11. 자동차관리 - 자동차정보상세조회 - 자동차등록 - 모델목록 조회
export function getVehiclesModels(query) {
  const accessToken = getAuthorization();
  return Api.get(`/api/vehicle-mgmt/vehicles/models`, accessToken, query);
}

// H2-1. 자동차정보관리 - 자동차 이관 - 실소유차량 검색 (기업:C, 회원:M)
export function getVehiclesListByOwner(queryParams) {
  const accessToken = getAuthorization();
  const query = {...queryParams};
  return Api.get(`/api/vehicle-mgmt/vehicles/real-owner`, accessToken, query);
}

// H2-2. 자동차정보관리 - 자동차 이관 - 실소유차량 검색 (기업:C, 회원:M)
export function getVehiclesListTransfer(queryParams) {
  const accessToken = getAuthorization();
  const query = {...queryParams};
  return Api.patch(`/api/vehicle-mgmt/vehicles/vehicle-ownership-transfer`, accessToken, query);
}



// K1-1 파트너관리 - 파트너 정보 - 목록조회
export function getPartnerList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = {
    page,
    size,
    ...queryList,
  };
  return Api.get(`/api/partner-mgmt/partners`, accessToken, query);
}

// K1-1 파트너관리 - 파트너 정보 - 파트너 등록하기
export function postPartnerRegister(body) {
  const accessToken = getAuthorization();
  return Api.post(`/api/partner-mgmt/partners`, accessToken, body);
}

// K1-1 파트너관리 - 파트너 정보 - 등록하기 - 파트너 법인등록번호 중복체크
export function getCorpNumCheck(corpNum, comTypeCd) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/partner-mgmt/partners/corpNum/${corpNum}/${comTypeCd}`,
    accessToken,
  );
}

// K1-1 파트너관리 - 파트너 정보 - 등록하기 - 파트너아이디 중복체크
export function getPartnerIdCheck(partnerId) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/partner-mgmt/partners/partner-id/${partnerId}`,
    accessToken,
  );
}

// K1-1 파트너관리 - 파트너 정보 - 상세조회
export function getPartnerDetail(comId) {
  const accessToken = getAuthorization();
  return Api.get(`/api/partner-mgmt/partners/${comId}`, accessToken);
}

// K1-1 파트너관리 - 파트너 정보 - 수정
export function patchPartnerDetail(comId, body) {
  const accessToken = getAuthorization();
  return Api.patch(`/api/partner-mgmt/partners/${comId}`, accessToken, body);
}

// K1-2. 파트너관리 - 파트너 정보 - 파트너어드민 로그인
export function getPartnerAdminLogin(adminNo) {
  const accessToken = getAuthorization();
  return Api.get(`/api/sign-in/member/${adminNo}/partner-admin`, accessToken);
}

// K3-1 파트너관리 - 관리자 정보 - 목록조회
export function getAdminList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = {
    page,
    size,
    ...queryList,
  };
  return Api.get(`/api/partner-mgmt/admins`, accessToken, query);
}

// K3-1 파트너관리 - 관리자 정보 - 관리자 등록
export function postAdminRegister(body) {
  const accessToken = getAuthorization();
  return Api.post(`/api/partner-mgmt/admins`, accessToken, body);
}

// K3-1 파트너관리 - 관리자 정보 - 관리자 상세조회
export function getAdminDetail(adminNo) {
  const accessToken = getAuthorization();
  return Api.get(`/api/partner-mgmt/admins/${adminNo}`, accessToken);
}

// K3-1 파트너관리 - 관리자 정보 - 관리자 수정
export function patchAdminDetail(adminNo, body) {
  const accessToken = getAuthorization();
  return Api.patch(`/api/partner-mgmt/admins/${adminNo}`, accessToken, body);
}

// K3-1 파트너관리 - 관리자 정보 - 비밀번호 재설정 메일 발송
export function postEmailPwd(adminNo) {
  const accessToken = getAuthorization();
  return Api.post(
    `/api/partner/sign-in/member/${adminNo}/email-pwd`,
    accessToken,
  );
}

// L4-1 파트너관리 - 엔지니어 정보 - 목록조회
export function getEngineersList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = {
    page,
    size,
    ...queryList,
  };
  return Api.get(`/api/partner-mgmt/engineers`, accessToken, query);
}

// L4-1 파트너관리 - 엔지니어 정보 - 상세조회
export function getEngineersDetail(adminNo) {
  const accessToken = getAuthorization();
  return Api.get(`/api/partner-mgmt/engineers/${adminNo}`, accessToken);
}

// L4-1 파트너관리 - 엔지니어 정보 - 수정
export function putEngineersDetail(adminNo, body) {
  const accessToken = getAuthorization();
  return Api.patch(`/api/partner-mgmt/engineers/${adminNo}`, accessToken, body);
}

// L4-1 파트너관리 - 엔지니어 정보 - 등록
export function postEngineers(body) {
  const accessToken = getAuthorization();
  return Api.post(`/api/partner-mgmt/engineers`, accessToken, body);
}

// L4-1 파트너관리 - 엔지니어 정보 - 등록 - 파트너 검색
export function getPartnerName(partnerName) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/partner-mgmt/partners/name?partnerName=${partnerName}`,
    accessToken,
  );
}

// K5-1 파트너관리 - 파트너 공지사항 - 목록조회
export function getNoticeList(page, size, queryList) {
  const accessToken = getAuthorization();
  const query = {
    page,
    size,
    ...queryList,
  };
  return Api.get(`/api/partner-mgmt/notice`, accessToken, query);
}

// K5-1 파트너 관리 - 파트너 공지사항 - 공지사항 게시글 상세조회
export function getNoticeImportant() {
  const accessToken = getAuthorization();
  return Api.get(`/api/partner-mgmt/notice/important`, accessToken);
}

// J5-1 파트너 관리 - 파트너 공지사항 - 공지사항 게시글 작성
export function postNotice(body) {
  const accessToken = getAuthorization();
  return Api.post(`/api/partner-mgmt/notice`, accessToken, body);
}

// J5-1 파트너 관리 - 파트너 공지사항 - 공지사항 게시글 상세조회
export function getNoticeDetail(partnerId) {
  const accessToken = getAuthorization();
  return Api.get(`/api/partner-mgmt/notice/${partnerId}`, accessToken);
}

// J5-1 파트너 관리 - 파트너 공지사항 - 공지사항 게시글 수정
export function putNoticeDetail(partnerId, body) {
  const accessToken = getAuthorization();
  return Api.put(`/api/partner-mgmt/notice/${partnerId}`, accessToken, body);
}

// J5-1 파트너 관리 - 파트너 공지사항 - 공지사항 게시글 삭제
export function deleteNoticeDetail(partnerId) {
  const accessToken = getAuthorization();
  return Api.delete(`/api/partner-mgmt/notice/${partnerId}`, accessToken);
}

// J5-1 파트너 관리 - 파트너 공지사항 - 공지사항 중요글 개수 조회
export function getImportantCount(cateId) {
  const accessToken = getAuthorization();
  return Api.get(
    `/api/partner-mgmt/notice/${cateId}/important-count`,
    accessToken,
  );
}
